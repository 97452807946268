<template>
  <div class="blog-wrapper">
    <!-- content -->
    <b-row class="blog-list-wrapper">
      <!-- osces -->
      <b-col md="9" oncontextmenu="return false;">
        <b-card no-body>
          <video
            width="100%"
            height="auto"
            controls
            controlslist="nodownload"
            :src="getUrl + 'penyimpanan/osce/' + dataOsce.video"
            type="video/mp4"
            @loadedmetadata="startTimer"
            ref="video"
            ></video>
          <b-card-body>
            <b-card-title>
              <h5>{{ dataOsce.title }}</h5>
            </b-card-title>
            <b-media no-body>
              <b-link class="brand-logo" href="/">
                <b-img
                  :src="require('@/assets/images/avatars/13-small.png')"
                  style="width: 20px; height: 20px"
                  alt="logo"
                  class=""
                ></b-img>
              </b-link>
              <b-media-body>
                <small class="text mr-50"> by</small>
                <small>
                  <b-link class="text-body">Optimal</b-link>
                </small>
                <span class="text-muted ml-75 mr-50">|</span>
                <small class="text">Bimbingan Belajar</small>
              </b-media-body>
            </b-media>
            <br/>
            <b-row v-if="isLoading == true">
                  <b-col md="6" xl="3">
                    <b-button variant="danger" style="width: 100%">
                      <feather-icon icon="ArrowLeftIcon" style="color: white" />
                      <span class="align-middle"><b style="color: white">Tidak, Ulang</b></span>
                    </b-button>
                  </b-col>
                  <b-col md="6" xl="6"> 
                    Apakah anda sudah memahami pelajaran di video ini?
                  </b-col>
                  <b-col md="6" xl="3" >
                    <b-button variant="success" style="width: 100%">
                      <span class="cursor-pointer"><b style="color: white">Ya, Lanjut</b></span>
                      <feather-icon icon="ArrowRightIcon" style="color: white" />
                    </b-button>
                  </b-col>
                </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card class="shadow-none faq-contact-card">
          <app-timeline
            v-for="fitur_activity in listosce"
            v-bind:key="fitur_activity.id"
          >
            <b-link :href="'/user/osce/detail/' + fitur_activity.id">
              <app-timeline-item
                :title="fitur_activity.title"
                variant="primary"
                style="padding-bottom: 14px"
              />
            </b-link>
          </app-timeline>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
  
  <script>
import {
  BFormInput,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BLink,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BCard,
  BRow,
  BCol,
  BBadge,
  BCardText,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormTextarea,
  BFormCheckbox,
  BButton,
  BEmbed,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ContentWithSidebar from "@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue";
import MenuComponent from "@/views/Menu.vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
export default {
  components: {
    BFormInput,
    AppTimeline,
    AppTimelineItem,
    BEmbed,
    BMedia,
    BAvatar,
    MenuComponent,
    BMediaAside,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BBadge,
    BCardText,
    BDropdown,
    BForm,
    BDropdownItem,
    BFormTextarea,
    BFormCheckbox,
    BButton,
    ContentWithSidebar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dataOsce: [],
      datapaket: [],
      listosce: [],
      selected: "left",
      selected2: "right",
      level: localStorage.getItem("Ulevel"),
      getUrl: "",
      interval: null,
      isLoading: false
    };
  },
  async created() {
    await this.getData();
    await this.getDataListosce();
    this.getDatapaket();
    this.getUrl = process.env.VUE_APP_URL;
  },
  mounted(){
    this.checkAuth();
  },
  methods: {
    startTimer() {
      this.interval = setInterval(() => {
        // console.log("CEK ALL", this.$refs.video.currentTime);
        // console.log("CEK ALL 2", this.$refs.video.duration)
        if (this.$refs.video.currentTime > this.$refs.video.duration - 10) {
          this.isLoading = true;
        }
      }, 1000);
    },

    beforeDestroy() {
    clearInterval(this.interval);
  },

    checkAuth() {
      var sessionCheck = localStorage.getItem("Uid");
      if (sessionCheck == null || sessionCheck == "") {
        this.$router.push({ name: "login" });
      }
    },
    async getDataListosce() {
      await this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "osces/view")
        .then((res) => {
          this.listosce = res.data.data;
        });
    },
    async getData() {
      const id = this.$route.params.id;
      await this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "osces/search/" + id)
        .then((res) => {
          this.dataOsce = res.data.data;
        });
    },
    async getDatapaket() {
      await this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "pakets/view_limit")
        .then((res) => {
          this.datapaket = res.data.data;
        });
    },
    formatPrice(value) {
      if (value != null) {
        const val = Number(value).toFixed(2).replace(/\.00$/, "");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
    },
  },
};
</script>
  
  <style lang="scss">
@import "@core/scss/vue/pages/page-blog.scss";
</style>
  